import { USER_INFO_KEY } from '@auth/config'
import { IS_SERVER } from '@config'
import { decodeToken } from '@plugin/http/lib/utils'
import { getRefreshToken, getToken, getUserInfo } from '@plugin/login'
import { object2camelCase } from '@utils'
import { atom } from 'recoil'

export default atom({
  key: 'login',
  default: {
    info: null,
    token: null,
    refreshToken: null,
    signupSource: undefined,
    signin: false,
    signup: false,
    forgot: false,
    registered: false,

    // 没有密码提示
    noPassword: false,
    noPasswordUser: null,

    // 绑定Facebook
    bindFacebook: false,
    bindFacebookUser: null,

    // 登录/注册成功后回调
    doneHandle: null,
  },
  effects: [
    ({ setSelf, onSet }) => {
      if (IS_SERVER) return

      const token = getToken()
      const refreshToken = getRefreshToken()
      let info = getUserInfo()

      if (token && refreshToken && !info) {
        // 有token，但是没有info，有可能是从app过来的
        info = decodeToken(token)
        if (info) {
          info = object2camelCase(JSON.parse(info))
          info.name = [info.firstName, info.lastName].join(' ')
        }
      }

      if (token && refreshToken) {
        setSelf(d => ({ ...d, info, token, refreshToken }))
      }

      onSet(newValue => {
        if (newValue.info) {
          localStorage.setItem(USER_INFO_KEY, JSON.stringify(newValue.info))
        }
      })
    }
  ]
})
